.modal {
  min-height: 90vh;
  max-height: 90vh;
  min-width: 90vw;
  max-width: 90vw;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.tableDiffCell {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  button {
    justify-self: flex-end;
    margin-left: 4px;

    background: none !important;
    border: none;
    padding: 0 !important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
}

.wordsAdded {
  margin-right: 4px;
  color: green;
}
.wordsRemoved {
  margin-right: 4px;
  color: red;
}
