$main-width: 1274px;

.app {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  > .app-container {
    min-width: $main-width;
    width: $main-width;
  }
}
