.source {
  max-width: 600px;
  text-decoration: none;

  span {
    margin: 0 8px;
  }

  a {
    color: #004466;
  }

  a:hover {
    text-decoration: underline;
  }
}
