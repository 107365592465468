.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  & .error {
    color: red;
  }
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg {
    height: 22px;
    width: 22px;
  }
  & span {
    margin-left: 4px;
  }
}
