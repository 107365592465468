.tableDiffCellModal {
  display: flex;
  flex-direction: column;
  position: relative;

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 66px;

    > button {
      flex: 0 0 20px;
      height: 20px;
      position: absolute;
      top: 0;
      right: 0;
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
    }

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      line-height: 20px;
      align-items: center;
      margin-bottom: 20px;

      > h2 {
        vertical-align: bottom;
        font-size: 18px;
        margin: 0 10px 6px 0;
      }

      > a {
        font-size: 13px;
        color: #004466;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.timestamps {
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
  height: 18px;

  > div {
    margin-left: 100px;
    flex: 1 1 50%;
  }
}

.diffContainer {
  height: calc(90vh - 97px);
  width: 100%;
  overflow-y: scroll;
}
