.searchFormContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.searchForm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 900px;

  .searchBox {
    flex: 1;
    display: flex;
    height: 44px;
    border: 1px solid #5f6368;
    box-shadow: none;
    border-radius: 24px;
    margin: 0 auto;
    width: 638px;
    width: auto;
    max-width: 584px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & svg {
      height: 30px;
      width: 30px;
      margin-left: 10px;
    }

    & input {
      width: 523px;
      margin-right: 10px;
      border: none;
      height: 40px;
      background-color: transparent;
      outline: none;
      font-size: 20px;
      padding-left: 12px;
    }
  }

  & label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin-left: 10px;
  }

  & button {
    color: #303134;
    border: 1px solid #303134;
    border-radius: 4px;
    background-color: #e8eaed;
    font-family: arial, sans-serif;
    font-size: 14px;
    margin: 11px 4px;
    padding: 0 16px;
    line-height: 27px;
    height: 36px;
    min-width: 54px;
    text-align: center;
    cursor: pointer;
    user-select: none;

    &:hover {
      box-shadow: 0 1px 3px rgb(23 23 23 / 24%);
      background-color: #303134;
      border: 1px solid #5f6368;
      color: #e8eaed;
    }
  }
}
