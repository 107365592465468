.header {
  font-family: arial, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  h1 {
    font-size: 22px;
    font-weight: 500;
  }
}

.userContextArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .userName {
    margin-right: 10px;
  }

  .profileImage {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 8px;
  }
}
