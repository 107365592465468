.noChangesFound {
  text-align: center;
  margin: 5rem 0;
}

.changesTable {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  > thead > tr {
    line-height: 20px;
    background-color: #009879;
    color: #ffffff;
    text-align: left;
    > th {
      padding: 12px 15px;
    }
  }

  > tbody {
    > tr {
      border-bottom: 1px solid #dddddd;
      > td {
        padding: 12px 15px;
      }
    }
    > tr:last-of-type {
      border-bottom: 2px solid #009879;
    }
  }
}

.nWordsColumn {
  width: 138px;
  min-width: 138px;
  max-width: 138px;
}
.destinationColumn {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}
.sourceColumn {
  width: 540px;
  min-width: 540px;
  max-width: 540px;
}

.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    background: none;
    border: none;
    padding: 0;
    font-size: 14;
    font-family: arial, sans-serif;
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  button,
  span {
    margin-left: 10px;
    margin-right: 10px;
  }
}
